import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import AboutUs from "../../components/en/AboutUs"
import Footer from "../../components/en/Footer"

class ContactPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | O nama</title>
          <meta
            name="description"
            content="Ćatić DOO je porodična firma koja je počela sa radom davne 1986 god. u privatnom vlasništvu gospodina Osmana Ćatića i bavila se transportom robe. U periodu od 1986. do 2005 god. ova naizgled mala porodična firma dobija svoj prostor i značajno mesto u svesti potrošača."
          />
          <meta name="image" content="https://waltz.rs/waltz_seo_image.jpg" />
          <meta itemprop="name" content="WALTZ | O nama" />
          <meta
            itemprop="description"
            content="Ćatić DOO je porodična firma koja je počela sa radom davne 1986 god. u privatnom vlasništvu gospodina Osmana Ćatića i bavila se transportom robe. U periodu od 1986. do 2005 god. ova naizgled mala porodična firma dobija svoj prostor i značajno mesto u svesti potrošača."
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_seo_image.jpg"
          />
          <meta name="og:title" content="WALTZ | O nama" />
          <meta
            name="og:description"
            content="Ćatić DOO je porodična firma koja je počela sa radom davne 1986 god. u privatnom vlasništvu gospodina Osmana Ćatića i bavila se transportom robe. U periodu od 1986. do 2005 god. ova naizgled mala porodična firma dobija svoj prostor i značajno mesto u svesti potrošača."
          />
          <meta
            name="og:image"
            content="https://waltz.rs/waltz_seo_image.jpg"
          />
          <meta name="og:url" content="https://waltz.rs/o-nama" />
          <meta name="og:site_name" content="WALTZ | O nama" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <AboutUs />
        <Footer />
      </Layout>
    )
  }
}
export default ContactPage
