import React from "react"
import image from "../../images/w_onama_img.jpg"
import slika2005 from "../../images/o_nama_2005.png"
import slika2011 from "../../images/o_nama_2011.png"
import slika2014 from "../../images/o_nama_2014.png"
import slika2018 from "../../images/o_nama_2018.png"

class AboutUs extends React.Component {
  render() {
    return (
      <section id="about_us_page" className="hero about-us-page default-page">
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-4 static-part is-right padding-0"></div>
            {/* STATIC PART END */}

            <div className="column is-8 content-part is-left padding-0">
              <div className="inner">
                <img className="_image" src={image} alt="Catic Company" />

                <p className="subtitle">
                  Ćatić company is a family business established in 1986.
                  privately owned by Mr. Osman Catic and main activities of the
                  company were related to goods transport. In the period from
                  1986 to 2005. this seemingly small family business started
                  getting a significant place in consumer awareness.
                </p>
                <div className="columns">
                  <div className="column padding-0">
                    <img src={slika2005} alt="Ćatic Company" />
                  </div>
                  <div className="column padding-0">
                    <p className="subtitle">
                      <span className="colorRed">2005.</span> In 2005, the
                      company changes its original name and becomes Ćatić
                      Company DOO the company which production becomes the
                      primary activity, which in the long run will justify the
                      trust given to consumers.
                    </p>
                    <span className="zigzag"></span>
                    <p className="subtitle">
                      <span className="colorRed">2007.</span> Year 2007 was the
                      year when consumers really liked those round wafer rolls
                      that positioned them and set them apart from other brands
                      that produce similar products. That product also becomes
                      the primary production activity of Ćatić company DOO.
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column padding-0">
                    <p className="subtitle">
                      The following years are of great importance for Catic
                      Company DOO.
                    </p>
                    <span className="zigzag"></span>
                    <p className="subtitle">
                      <span className="colorRed">2009.</span> In 2009, the HACCP
                      system is implemented, <br />
                      <span className="colorRed">2010.</span> In 2010, ISO
                      Certificate 9001: 2008 is introduced <br />
                      <span className="colorRed">2011.</span> In year 2011 the
                      process of milk and cocoa cream production begins, thus
                      expanding the production range of this company. Thanks to
                      this, Ćatić Company DOO was awarded at the 80th
                      International Agricultural Fair held in Novi Sad and
                      received a plaque for the quality of products and Waltz
                      cream received a gold medal for quality. It was a great
                      reward and an endorsement that was dedicated years ago.
                    </p>
                  </div>
                  <div className="column padding-0">
                    <img src={slika2011} alt="Ćatic Company" />
                  </div>
                </div>

                <div className="columns">
                  <div className="column padding-0">
                    <img src={slika2014} alt="Ćatic Company" />
                  </div>
                  <div className="column padding-0">
                    <p className="subtitle">
                      The product portfolio continued to expand. Consumers could
                      hardly wait for what will be the next offering from the
                      producer.
                    </p>
                    <span className="zigzag"></span>
                    <p className="subtitle">
                      <span className="colorRed">2014.</span> Waltz rolls
                      production begins. The HALAL certification covers all
                      Waltz products that they have produced before (Waltz roll,
                      Waltz cream and Waltz block). <br />
                      <span className="colorRed">2015.</span> The company
                      received EN ISO 22000 certificate. This was a major step
                      forward made by Ćatić Company DOO. <br />
                      <span className="colorRed">2017.</span> Ćatić Company DOO
                      is again awarded. This time, the confirmation came from
                      the international group "BISNODE". Ćatić company was
                      recognized as one of the five fastest growing companies in
                      the Republic of Serbia.
                    </p>
                  </div>
                </div>

                <p className="subtitle">
                  Today, the Waltz brand produces Cream rolls, Choko cream
                  rolls, Waltz cream rolls and Waltz block. Waltz products are
                  widespread in the markets of the former Yugoslavia, i.e.
                  Serbia, Montenegro, Bosnia and Herzegovina, Croatia,
                  Macedonia. In addition, the products can be found on the
                  shelves of Bulgaria, Austria, Canada, Iraq, Palestine.
                </p>
                <span className="zigzag"></span>
                <p className="subtitle">
                  This initially small family-owned company now employs over 120
                  full-time employees. More than 30 tons of products are stored
                  daily from the production facility and sent to distribution
                  channels in order to reach the shelves and final consumers on
                  time.
                </p>
                <span className="zigzag"></span>
                <p className="subtitle">
                  The path of development of Ćatić Company is based on family
                  values, employee satisfaction (care of staff, their needs,
                  regular payment of wages), which ultimately leads to happy and
                  satisfied employees.
                </p>
                <span className="zigzag"></span>
                <p className="subtitle">
                  Today, Ćatić company is modernly organized and market-oriented
                  company that constantly invests in quality and development.
                  The basis of the Ćatić company's policy are usage of
                  contemporary technology, a unique recipe, and meeting the
                  world's highest standards and requirements.
                </p>
                <img src={slika2018} alt="Ćatic Company" />

                <div class="videoWrapper">
                  <iframe
                    src="https://player.vimeo.com/video/313909288"
                    width="640"
                    height="360"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/*CONTENT PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default AboutUs
